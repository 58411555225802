// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:9850b284-017a-47b6-94db-66986be2cea1",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_DMXbSLYZA",
    "aws_user_pools_web_client_id": "2ruja5pqonha258in3jah1ojfb",
    "oauth": {
        "domain": "connectedtechcps.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://connectedtechcps.com/waitsignin",
        "redirectSignOut": "https://connectedtechcps.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
};


export default awsmobile;
