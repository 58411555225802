import React from 'react';
import ViewJson from './ViewJson';
import WordDoc from './WordDoc';
import ViewResponse from './ViewResponse';
import ConsolidatedResponse from './ConsolidatedResponse';

const RequestDetailsJSONTabs = ({ tabInfo, handleNewTabOpen, setLoading, setDisplayErrMsg, setDisplaySuccessMsg, reloadInformation }) => {
    if (tabInfo.tabtype === "word") {
        return (<WordDoc
            tabInfo={tabInfo}
            setLoading={setLoading}
            setDisplayErrMsg={setDisplayErrMsg}
            setDisplaySuccessMsg={setDisplaySuccessMsg}
        />)
    } else if (tabInfo.tabtype === "downstreamappresponse") {
        return (<ViewResponse
            tabInfo={tabInfo}
            setLoading={setLoading}
            setDisplayErrMsg={setDisplayErrMsg}
            reloadInformation={reloadInformation}
        />)
    } else if(tabInfo.tabtype === "consolidatedresponse"){
        return (<ConsolidatedResponse 
            tabInfo={tabInfo}
            setLoading={setLoading}
            setDisplayErrMsg={setDisplayErrMsg}
            reloadInformation={reloadInformation}
            handleNewTabOpen={handleNewTabOpen}
        />)
    }
    return <ViewJson
        tabInfo={tabInfo}
        setLoading={setLoading}
        setDisplayErrMsg={setDisplayErrMsg}
    />
}

export default React.memo(RequestDetailsJSONTabs);